import React, {useRef} from 'react'
import './contact.css'
import emailjs from '@emailjs/browser'

const Contact =()=> {
    const form = useRef();
   
        const sendEmail = (e) => {
          e.preventDefault();
      
          emailjs.sendForm('service_jwhyvyf', 'template_8bmq58i', form.current, 'r3_EvDa5zH6xmPCST')
            .then((result) => {
                console.log(result.text);
                e.target.reset();
                alert("Email sent!");
            }, (error) => {
                console.log(error.text);
            });
        };
  return (
    <div >
        <div  id='contact'>
            <h2 className='contactPageTitle'> Contact me/Contáctame </h2>
            <h2>office#:832-883-8297</h2>
            <span className='contactDesc'>Please fill out contact form below/Por favor, complete el formulario de contacto a continuación. </span>
            <form className='contactForm' ref={form} onSubmit={sendEmail} >
                  <input type='text' className='name' placeholder='Your Name/Tu Nombre ' name='from_name'/>
                  <br/>
                  <input type='email' className='email' placeholder='Your Email/Tu Correo Electrónico ' name='from_email'/>
                  <br/>
                   <textarea name='message' rows="5" placeholder='Please include your phone number and message./Por favor, incluye tu número y mensaje.' className='msg'></textarea>
                   <br/>
                   <button type='submit' value='sent' className='submit' > SUBMIT</button>
                    
        </form>
        </div>
      
    </div>
    
  )
  }
  export default Contact;
