import React from "react";
import './works.css';
import Portfolio1 from '../../assets/portfolio-6.png';
import Portfolio2 from '../../assets/portfolio-1.png';
import Portfolio3 from '../../assets/portfolio-2.png';
import Portfolio4 from '../../assets/portfolio-3.png';

  

const Works = () => {
  return(
  <section id="works">
    <h2 className="worksTitle">My Work</h2>
    <span className="worksDesc">I take pride in helping my community. </span>
    <span className="worksDesc">Me enorgullezco de ayudar a mi comunidad.. </span>
    <div className="worksImgs">
        <img src ={Portfolio1} alt='' className="worksImg"/> 
        <h2>Happy cilents are number 1 priority</h2>
        <h2>Clientes felices son la prioridad número uno</h2>
        <img src ={Portfolio2} alt='' className="worksImg"/> 
        <h2>Let us do the worrying for you.</h2>
        <h2>Permítanos preocuparnos por usted</h2>
        <img src ={Portfolio3} alt='' className="worksImg"/> 
        <h2>You're on the way to justice.</h2>
        <h2>Estás en camino hacia la justicia.</h2>
        <img src ={Portfolio4} alt='' className="worksImg"/> 
    </div>
   
   </section>
   )
}
export default Works;