import React from 'react'
import '../Footer/footer.css'

export default function footer() {
  return (
    <div className='footerContainer'>
 <footer className='footer'>
    Copyright All rights recieved 2023
 </footer>
 </div>
  )
}
