import React, { useState } from 'react'
import './navbar.css'
import logo from '../../assets/logo.png';
import { Link } from 'react-scroll';
import menu from '../../assets/menu.png'

const Navbar = () => {
    const[showMenu, setShowMenu] = useState(false)
  return (
    <nav className='navbar'>

          <div className='menu'>MENU</div>
         <img src={menu} alt='logo' className='mobMenu' onClick={()=> setShowMenu(!showMenu)} />
        
       
        <img src={logo} alt='logo' className='logo2'/>
        <div className='desktopMenu'>
       <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-300} duration={500} className='desktopMenuListItem'>home</Link>
       <Link activeClass='active' to='works' spy={true} smooth={true} offset={-290} duration={500} className='desktopMenuListItem'>about</Link>
       <Link activeClass='active' to='skills' spy={true} smooth={true} offset={-100} duration={500} className='desktopMenuListItem'>services</Link>
        </div>
        <button className='desktopMenuBtn' onClick={()=>{
            document.getElementById('contact').scrollIntoView({behavior: 'smooth'});
        }}>
            <img src='' alt='' className='desktopMenuImg'/> CONTACT ME 
            </button> 
            {/* <div className='menu'>MENU</div>
            <img src={menu} alt='logo' className='mobMenu' onClick={()=> setShowMenu(!showMenu)}/>
                */}
       
        <div className='navMenu'  style={{display: showMenu? 'flex': 'none'}}>
       
         
       <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-300} duration={500} className='listItem' onClick={()=> setShowMenu(false)}>home</Link>
       <Link activeClass='active' to='works' spy={true} smooth={true} offset={-290} duration={500} className='listItem'onClick={()=> setShowMenu(false)}>about</Link>
       <Link activeClass='active' to='skills' spy={true} smooth={true} offset={-100} duration={500} className='listItem' onClick={()=> setShowMenu(false)}>services</Link>
       <button className='mobMenuBtn' onClick={()=>{
            document.getElementById('contact').scrollIntoView({behavior: 'smooth'});
        }}>
            <img src='' alt='' className='desktopMenuImg'/> CONTACT ME 
            </button> 
        </div>
    </nav>
     
   
  )
}

export default Navbar
