import React from 'react'
import './skills.css';
import UIDesign from '../../assets/ui-design.png';
import wedDesign from '../../assets/app-design.png'

const Skills = ()=>{
return (
  <section id='skills'>
 <span className='skillTitle'>What I do.</span>
 <span className='desc'>A skilled attorney with over 37 years of experience.</span> 
 <div className='skillbar'>
    <img src={UIDesign} alt='' className='skillBarImg'/>
       <div className='skillBarText'>
        <h2>Leonard DeRosa</h2>
        <p>Spanish speaking attorney</p>
       </div>
 </div>
 <div className='skillbar'>
    <img src={wedDesign} alt='' className='skillBarImg'/>
       <div className='skillBarText'>
        <h2>Criminal Defense</h2>
        <p>DWI,CDLTraffic,Theft,Drug,Assault</p>
       </div>
 </div>
 <div className='skillbar'>
    <img src={UIDesign} alt='' className='skillBarImg'/>
       <div className='skillBarText'>
        <h2>Trial attorny</h2>
        <p>Will fight for you!</p>
       </div>
 </div>
 <div className='skillbar'>
    <img src={wedDesign} alt='' className='skillBarImg'/>
       <div className='skillBarText'>
        <h2>Counties</h2>
        <p>Harris, Brazoria, Jefferson, Liberty, Galveston, Fort Bend</p>
       </div>
 </div>
  </section>
  
);

}
export default Skills;
