import React from 'react'
import './intro.css'

// import { Link } from 'react-scroll'
// import btnImg from '../../assets/hireme.png'

export default function into() {
  return (
  <section id='intro'> 
 <div className='introContent'>
    <br></br>
<span className='hello'>
   Hello!
<br className='hello'/>Hola!</span> <br/>
<span className='intro2'>Your local Lawyer here to help the people.<br/>
  ¡Tu abogado local aquí para ayudar a la gente!</span>
  <div className='paragraph'>
  <p className='para'>Providing legal services encompassing a wide spectrum of criminal defenses, ranging from traffic violations to more severe criminal matters</p>
  <p className='para'>At DeRosa Lawfirm, our experienced legal team specializes in representing clients facing CDL (Commercial Driver's License) tickets and violations. With a deep understanding of the complexities surrounding CDL-related legal matters, we provide dedicated advocacy and strategic counsel to protect your driving record and livelihood. Our commitment to excellence and thorough knowledge of traffic laws allows us to navigate the nuances of CDL cases effectively. We prioritize swift resolutions and work tirelessly to minimize the impact of violations on your driving privileges. Trust DeRosa Law Firm to advocate for your rights and deliver comprehensive legal support tailored to your specific situation</p>
  <p className='para'>Ofreciendo servicios legales que abarcan una amplia gama de defensas criminales, desde multas de tráfico hasta asuntos criminales más graves</p>
  <p className='para'>En Firma Legal DeRosa, nuestro experimentado equipo legal se especializa en representar a clientes que enfrentan multas y violaciones relacionadas con la licencia de conducir comercial (CDL). Con un profundo entendimiento de las complejidades que rodean los asuntos legales relacionados con CDL, brindamos una defensa dedicada y asesoramiento estratégico para proteger su historial de conducción y su medio de vida. Nuestro compromiso con la excelencia y nuestro amplio conocimiento de las leyes de tráfico nos permite navegar eficazmente las sutilezas de los casos de CDL. Priorizamos soluciones rápidas y trabajamos incansablemente para minimizar el impacto de las infracciones en sus privilegios de manejo. Confíe en DeRosa Bufete de abogados para defender sus derechos y brindar un soporte legal integral adaptado a su situación específica</p>
{/* <br></br><Link><button className="btn">Contrátame.</button></Link> */}
</div>
<br/>
 </div>
 
 <br/>
      {/* <img className='bg' src={bg} alt='profile'></img> */}
  </section>
  )
}
