import Navbar from "./components/NavBar/navbar";
import Into from "./components/Intro/into";
import Skills from './components/Skills/skills'
import Contact from "./components/Contact/contact";
import Works from "./components/Works/works";
import Footer from "./components/Footer/footer";


function App() {
  return (
    <div className="App">
     <Navbar/>
     <Into/>
     <Skills/>
     <Works/>
     <Contact/>
     <Footer/>
     
    
    </div>
  );
}

export default App;
